import React from 'react'
import { Link, useStaticQuery } from 'gatsby'
import Layout from '../components/Layout'
import headerImage from '../img/bg.png'
import Img from "gatsby-image"



class BlogIndex extends React.Component {

    render() {
        const { data } = this.props
        const blogTitle = data.site.siteMetadata.blogTitle
        const blogDescription = data.site.siteMetadata.blogDescription
        const posts = data.allMdx.edges
    

        return (
            <Layout title={blogTitle} description={blogDescription}>
                
                <section className="section margin-top-navbar" style={{minHeight: '80vh'}}>
                  <div className="container">
                      {/* <div className="content"> */}
                      <div class="columns is-multiline is-mobile">
 
                        {posts.map(({ node }) => {
                          const title = node.frontmatter.title || node.fields.slug
                          return (
                            <article class="column is-6-desktop column is-6-tablet is-12-mobile" key={node.fields.slug}>
                              <Link to={node.fields.slug}>
                              <div class="card blog-entry">
                                <div>

                                
                                <div class="card-image">
                                  <figure class="image">
                                    {/* <img src="https://images7.alphacoders.com/303/303995.jpg" alt="Placeholder image"/> */}
                                    <Img fluid={node.fields.thumbnailImage.childImageSharp.fluid} alt={title} style={{height: '100%'}}/>
                                  </figure>
                                </div>
                                <div class="card-content">
                                  <p class="title is-3">
                                  {title}
                                  </p>
                                </div>
                                </div>
                                <div class="card-content">
                                  <div class="content">
                                    {node.frontmatter.date} - {node.frontmatter.description} 
                                  </div>
                                </div>
                              </div>
                              </Link>
                            </article>
                          )
                        })}

                      </div>

                      </div>
                  {/* </div> */}
                </section>

            </Layout>
        )
    }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        blogTitle
        blogDescription
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
            thumbnailImage {
              childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          frontmatter {
            date(formatString: "DD/MM/YYYY")
            title
            description
          }
        }
      }
    }
  }
`